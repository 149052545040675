<template>
  <div class="update-status">
    <b-form-checkbox
        v-if="user && user.profile.gender !== 'female'"
        v-model="onlineStatus"
        class="custom-control-dark"
        switch
        button-variant="primary"
        :disabled="!user"
    />
    <span :class="onlineStatus ? 'green-text' : 'red-text'">
      {{ onlineStatus ? $t('Online') : $t('Offline') }}
    </span>
  </div>
</template>

<script>
import { BFormCheckbox } from 'bootstrap-vue'
import { gtmTrackNewEvent, throwDefaultError } from '@/helpers/helpers'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BFormCheckbox,
  },
  data() {
    return {
      onlineStatus: false,
      user: null,
      watchOnlineStatus: false,
    }
  },
  created() {
    this.getMe()
  },
  computed: {
    userOnlineStatus() {
      return this.$store.getters['auth/userOnlineStatus']
    }
  },
  watch: {
    onlineStatus(val) {
      if (this.watchOnlineStatus) {
        this.updateOnlineStatus()

        gtmTrackNewEvent('changeonlinestatus', {
          category: 'Hesap',
          action: 'ChangeOnlineStatus',
          label: val ? 'Online' : 'Offline',
        })
      }
    },
    userOnlineStatus(val) {
      this.watchOnlineStatus = false
      this.onlineStatus = val === 'online'
      setTimeout(() => {
        this.watchOnlineStatus = true
      }, 11)
    }
  },
  methods: {
    getMe() {
      useJwt.getMe()
          .then(response => {
            this.watchOnlineStatus = false
            this.user = response.data.body
            this.onlineStatus = this.user.profile.onlineStatus === 'online'
            this.$store.dispatch('auth/updateUser', this.user)
            setTimeout(() => {
              this.watchOnlineStatus = true
            }, 11)
          })
          .catch(error => {
            throwDefaultError(this.$toast, error)
          })
    },
    updateOnlineStatus() {
      this.loading = true

      useJwt.updateOnlineStatus(this.onlineStatus ? 'online' : 'offline')
          .then(response => {
            this.onlineStatus = response.data.body.onlineStatus === 'online'
            this.getMe()
          })
          .catch(() => {

          })
          .finally(() => { this.loading = false })
    }
  }
}
</script>

<style scoped lang="scss">
.update-status {
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    width: 46px;
    line-height: 18px;
    font-size: 13px;
    font-weight: bold;

    &.green-text {
      color: #0bb708 !important;
    }
    &.red-text {
      color: #dc5854 !important;
    }
  }
}
</style>
