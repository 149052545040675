<template>
  <layout-vertical>
    <div v-if="!['chat'].includes($route.name)">
      <transition name="slide-fade">
        <div v-if="campaign && campaign.campaignHeader && campaign.webProduct && !['market', 'buy', 'payment.finalize', 'chat'].includes($route.name)" class="row">
          <div class="col-sm-12 offset-lg-3 col-lg-6">
            <div class="boho-area-1">
              <div>
                <p class="boho-area-1__title">
                  {{ campaign.campaignHeader }}
                </p>
                <p v-if="campaign.campaignText" class="boho-area-1__text">
                  {{ campaign.campaignText }}
                </p>
              </div>
              <div>
                <router-link :to="{name: 'buy', params: {productId: campaign.webProduct}}">
                  <b-button
                      class="boho-area-1__button"
                  >
                    Hemen Al
                  </b-button>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </transition>

      <router-view />
    </div>

    <router-view v-else />

    <app-customizer
      v-if="showCustomizer"
      slot="customizer"
    />
  </layout-vertical>
</template>

<script>
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
import { $themeConfig } from '@themeConfig'
import useJwt from '@/auth/jwt/useJwt'
import { showNotify, throwDefaultError } from '@/helpers/helpers'
import { BButton, BImg, BModal } from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BModal,

    AppCustomizer,
    LayoutVertical,
  },
  data() {
    return {
      loading: false,
      showCustomizer: $themeConfig.layout.customizer,
    }
  },
  created() {
    // this.getCampaigns()
  },
  watch: {
    campaign() {
      console.log('this.campaign', this.campaign)
      if (this.campaign && this.campaign.campaignId) {
        useJwt.campaignsDisplayed({campaignId: this.campaign.campaignId})
      }
    }
  },
  computed: {
    campaigns() {
      return this.$store.getters['app/campaigns']
    },
    campaign() {
      return this.$store.getters['app/campaign']
    },
    modalCall: {
      get: function () {
        return this.$store.getters['app/getModalCall']
      },
      set: value => {
        this.$store.dispatch('app/setModalCall', value)
      }
    },
    modalCallUser() {
      return this.$store.getters['app/getModalCallUser']
    }
  },
  methods: {
    closeModal() {
      this.modalCall = false
    },
    call() {
      this.$store.dispatch('app/setModalCallUser', this.modalCallUser)
      this.$store.dispatch('app/setModalCall', true)
      // if (this.modalCallUser) {
      //   if (this.modalCallUser.profile?.callType == 2) {
      //     this.$store.dispatch('app/setModalDownloadMobileApp', true)
      //   } else {
      //     if (!this.$store.getters['auth/userPhoneNumber']) {
      //       this.$store.dispatch('app/setModalAddPhoneNumberShowDiamond', false)
      //       this.$store.dispatch('app/setModalAddPhoneNumber', true)
      //       this.$store.dispatch('app/setVerificationType', 'phone')
      //     } else {
      //       this.loading = true
      //       useJwt.callUser(this.modalCallUser.id)
      //           .then(response => {
      //             showNotify(this.$toast, 'Arama başarılı', 'success')
      //           })
      //           .catch(error => {
      //             const { response } = error
      //             throwDefaultError(this.$toast, error)
      //             if (response && response.data?.header?.error?.errorCode == 215) {
      //               this.$store.dispatch('app/setModalAddPhoneNumberShowDiamond', false)
      //               this.$store.dispatch('app/setModalAddPhoneNumber', true)
      //               this.$store.dispatch('app/setVerificationType', 'phone')
      //             }
      //           })
      //           .finally(() => { this.loading = false })
      //     }
      //   }
      // }
    },
    getCampaigns() {
      useJwt.campaigns()
          .then(response => {
            console.log('campaigns', response)
            this.$store.dispatch('app/setCampaigns', response.data.body)
          })
    }
  }
}
</script>

<style lang="scss" scoped>
.boho-area-1 {
  background-color: #B03CF2;
  background-image: url("~@/assets/images/bg/purple-diamond-bg.png");
  background-repeat: no-repeat;
  background-position: center center;
  padding: 20px;
  border-radius: 14px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .boho-area-1__title {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 4px;
  }

  .boho-area-1__text {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 4px;
    border-bottom: 2px solid rgba(96, 179, 237, 0.99);
  }

  .boho-area-1__button {
    padding: 8px 16px;
    border-radius: 16px;
    background-color: #f3e6fb !important;
    transition: all .2s ease-in-out;
    color: #3F1186 !important;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}
</style>
